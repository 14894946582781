import React, { useState, useEffect, useRef, useCallback } from "react";
import Background from "./assets/road.png";
import axios from "axios";
import Clarity from "@microsoft/clarity";
import Hotjar from "@hotjar/browser";

const HOST_NAME = "https://api.thrivingworkplace.in";
// const PROJECT_ID = "pc6d5au1w4";
// Clarity.init(PROJECT_ID);
const siteId = 5239143;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

const App = () => {
  const [showSurvey, setShowSurvey] = useState(false);

  const [accessCode, setAccessCode] = useState("");

  const [zohoCode, setZohoCode] = useState("");

  const [animationClass, setAnimationClass] = useState("");

  const [animationCount, setAnimationCount] = useState(0);

  const [isError, setIsError] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);

  const [errorBorder, setErrorBorder] = useState(null);

  const [height, setHeight] = useState(null);

  useEffect(() => {
    setAnimationClass("animate-slideRight");
    setAnimationCount((prevCount) => prevCount + 1);
    setHeight(window.innerHeight);
  }, []);

  const renderSurvey = async () => {
    if (accessCode) {
      await axios
        .post(`${HOST_NAME}/survey/force-survey`, {
          // .post("http://192.168.68.111:4000/survey/force-survey", {
          aspireId: accessCode.trim(),
        })
        .then(async (res) => {
          console.log(res.data);
          setZohoCode(res.data.data.findAspireId.shortUrl.slice(-3));
          setShowSurvey(true);
          await axios
            .post(`${HOST_NAME}/survey/code-records`, {
              accessCode: accessCode.trim(),
              isVerified: true,
            })
            .then((res) => console.log("Post invalid code"))
            .catch((e) => console.log(e));
        })
        .catch(async (e) => {
          console.log(e);
          setIsError(true);
          setErrorMessage(e.response.data.message);
          setAccessCode("");
          setErrorBorder("border-1 border-red-600");
          if (e.response.data.isValid === false) {
            await axios
              .post(`${HOST_NAME}/survey/code-records`, {
                accessCode: accessCode.trim(),
                isVerified: false,
              })
              .then((res) => console.log("Post invalid code"))
              .catch((e) => console.log(e));
          }
        });
    }
  };

  const handleChange = useCallback((e) => {
    setAccessCode(e.target.value);
  }, []);

  const handleKeyDown = async (e) => {
    if (e.key === "Enter") {
      setAccessCode(e.target.value);
      await axios
        .post(`${HOST_NAME}/survey/force-survey`, {
          // .post("http://192.168.68.111:4000/survey/force-survey", {
          aspireId: e.target.value.trim(),
        })
        .then(async (res) => {
          console.log(res.data);
          setZohoCode(res.data.data.findAspireId.shortUrl.slice(-3));
          setShowSurvey(true);
          await axios
            .post(`${HOST_NAME}/survey/code-records`, {
              accessCode: accessCode.trim(),
              isVerified: true,
            })
            .then((res) => console.log("Post invalid code"))
            .catch((e) => console.log(e));
        })
        .catch(async (e) => {
          console.log(e.response.data.message);
          setIsError(true);
          setErrorMessage(e.response.data.message);
          setAccessCode("");
          setErrorBorder("border-1 border-red-600");
          if (e.response.data.isValid === false) {
            await axios
              .post(`${HOST_NAME}/survey/code-records`, {
                accessCode: accessCode.trim(),
                isVerified: false,
              })
              .then((res) => console.log("Post invalid code"))
              .catch((e) => console.log(e));
          }
        });
    }
  };

  const Login = () => {
    return (
      <div className="flex justify-center items-center bg-[#0074B9] h-screen">
        <div className="flex bg-white w-[90%] h-auto md:h-[90%] pb-6 rounded-md flex-col pl-4 pr-4 md:w-[65%] md:p-0">
          <div className="flex flex-row items-center justify-between mt-4 md:pl-24 md:pr-24 md:mt-[1rem]">
            <div className="w-[47%] md:w-[10%]">
              <img alt="gearup-logo" src={require("./assets/gearup.png")} />
            </div>
            <div className="w-[47%] md:w-[10%]">
              <img alt="lakshya-logo" src={require("./assets/lakshya.png")} />
            </div>
          </div>
          <p className="font-semibold text-3xl text-[#0074B9] mt-4 md:text-6xl md:w-[80%] md:pl-24 md:pr-24">
            Your voice drives <br /> our next big move
          </p>
          <div className="flex justify-end mt-2 md:w-[100%] md:mt-[-6rem]">
            <img
              className="h-[90%] w-full md:w-[40%]"
              alt="people"
              src={require("./assets/people.png")}
            />
          </div>

          <p className="text-xl text-[#003E77] md:pl-24 md:mt-[-5rem]">
            Be Part of Change
          </p>
          <div className="flex mt-2 flex-col md:pl-24">
            <div className="flex flex-row justify-between items-center">
              <div className="w-[45%]">
                <input
                  className={`rounded-lg p-2 w-[100%] border border-[#0074B9]`}
                  type="text"
                  autoFocus
                  onKeyDown={handleKeyDown}
                  placeholder="Access Code"
                  value={accessCode}
                  onChange={handleChange}
                />
                {isError !== null && (
                  <p className="mt-2 text-[#ffb3b3] font-semibold">
                    {errorMessage}
                  </p>
                )}
              </div>
              <div className="w-[45%]">
                <p className="text-xs text-[#0074B9]">
                  Check your WhatsApp / Email for the Access Code
                </p>
              </div>
            </div>
            <div className="flex flex-row justify-between items-center mt-3">
              <div
                onClick={renderSurvey}
                className="flex justify-center items-center w-[45%] bg-[#0074B9] p-2 rounded-lg"
              >
                <p className="text-white">Take Survey</p>
              </div>
              <div className="w-[50%] md:w-[45%] flex flex-row gap-2">
                <img
                  className="w-7 h-auto"
                  alt="force-logo"
                  src={require("./assets/force.png")}
                />
                <img
                  className="w-7 h-auto"
                  alt="force-logo"
                  src={require("./assets/jayahind.png")}
                />
                <img
                  className="w-7 h-auto"
                  alt="force-logo"
                  src={require("./assets/mtu.png")}
                />
                <img
                  className="w-12 h-auto md:w-16"
                  alt="force-logo"
                  src={require("./assets/aspire.png")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Survey = () => {
    return (
      <div>
        <iframe
          title="Embedded Content"
          width="100%"
          height={window.innerHeight}
          src={"https://zohsy.in/" + zohoCode}
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>
    );
  };

  if (showSurvey) {
    return <Survey />;
  } else {
    return <Login />;
  }
};

export default App;
